.register-main-container.register-container {
  line-height: 1.3;
  border: 0px solid #000;
  padding: 0;
  width: 100%;
  top: 25px;
  .form {
    // font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .notice {
      width: 100%;
      flex-direction: column;
      background-color: #4f4c4c;
      padding: 12px 0px;
      margin-top: 0 !important;
      .text {
        font-size: 1.5rem;
      }
    }
    .required-form {
      width: 80%;
      .required {
        color: red;
      }
      .tel-area {
        .tel-text {
          font-size: 1.2rem;
          color: #000;
          font-weight: bold;
        }
        #contact {
          font-size: 1.2rem;
          border-bottom: 3px solid #000 !important;
          padding-left: 0;
        }
      }
    }
    .optional-form {
      width: 80%;
      color: #000;
      // font-size: 18px;
      .optional {
        // font-size: 20px;
        color: blue;
        display: block;
      }
      .password-area {
        span {
          font-weight: bold;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox  */
        input[type="number"] {
          -moz-appearance: textfield;
        }
        .password {
          display: flex;
          border-bottom: 3px solid #000;
          #password {
            padding-left: 0;
          }
          label {
            padding-left: 0;
          }
        }
        p {
          color: #5a5c69;
        }
      }
      .name-area {
        border-bottom: 3px solid #000;
        margin-bottom: 0;

        span {
          font-weight: bold;
        }

        #name {
          padding-left: 0;
        }
      }

      .birth-area {
        border-bottom: 3px solid #000;
        margin-bottom: 0;

        span {
          font-weight: bold;
        }

        #birthdate {
          padding-left: 0;
          // appearance: none;
          // -webkit-appearance: none;
          // -moz-appearance: none;
          background-color: #fff;
        }
      }
    }

    .gender-area {
      span {
        font-weight: bold;
      }
    }

    .info-area {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      // font-size: 16px !important;

      input[type="checkbox"] {
        transform: scale(1.2);
      }
    }

    .register-footer {
      .btn {
        font-size: 1.2rem;
      }
    }
  }
}
