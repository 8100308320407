table.accml-table {
  width: 100%;
  @media (max-width: 1400px) {
    width: 80rem;
  }
}

div.point-modal-body {
  padding: 0rem 2rem;
}
.accml-modal-table {
  width: 38rem;

  input[type="text"] {
    width: 150px;
  }

  tr {
    border-bottom: 1px solid #e3e6f0;

    &:last-child {
      border-bottom: none;
    }

    td {
      height: 5rem;
    }
  }
}

.usepol-table {
  width: 25rem;
  td {
    height: 4rem;
  }
}

@media (min-width: 576px) {
  .col-excel-down {
    flex: 0 0 14.5%;
    max-width: 14.5%;
  }
}

tr.is_cancel_row {
  background-image: linear-gradient(to right, red 99%, transparent 50%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 98% 1px;
}
