body {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

body,
p,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
table,
th,
td,
form,
fieldset,
legend,
input,
textarea,
button,
select {
  margin: 0;
  padding: 0;
}

input,
select {
  border: 1px solid darkgray !important;
}

.menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    display: block;
  }
}

div.title {
  h5 {
    font-weight: 900;
  }
}

.now-date {
  margin-left: 1.2rem;
}

.mt-6,
.my-6 {
  margin-top: 8rem !important;

  @media (max-width: 1024px) {
    margin-top: 5.5rem !important;
  }
}

.c-pp {
  padding: 11rem 3rem !important;

  @media (max-width: 1024px) {
    padding: 3rem !important;
  }
}

.c-btn-primary {
  color: #fff;
  background-color: #1aaafb;
  border-color: #1aaafb;
}

#container-wrapper {
  width: 100%;
  overflow-x: hidden;
  background-color: #f8f9fc;
}

div.padding-r-zero {
  display: flex;
  padding-right: 0 !important;
}
div.padding-l-zero {
  padding-left: 0 !important;
}

.margin-r-05 {
  margin-right: 0.5rem;
}

.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12 {
  float: left;
}

.input-con span {
  position: absolute;
  top: 7px;
  right: 3px;
}

div.justify-content-sm-around {
  justify-content: space-around !important;
}

/***************** 아이콘 ****************/
.icon-info {
  font-size: 40px;
  font-weight: 900;
  color: #4e73df;
  margin-right: 15px;

  &.icon-cancel {
    color: #e74a3b;
  }
}

.icon-logout {
  font-weight: 900;
  margin-right: 10px;
}

/**
  * Dots flow
  *
  * @author jh3y - jheytompkins.com
*/
.dots-flow:before {
  -webkit-animation: dots-flow 0.85s infinite ease;
  animation: dots-flow 0.85s infinite ease;
  border-radius: 100%;
  content: "";
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -40px);
  transform: translate(-50%, -40px);
  width: 16px;
}

@-webkit-keyframes dots-flow {
  0%,
  100% {
    -webkit-box-shadow: -26px 32px 0px 0 #e0e0e0, 0px 32px 0 0 #e0e0e0,
      26px 32px 0 0 #e0e0e0;
    box-shadow: -26px 32px 0px 0 #e0e0e0, 0px 32px 0 0 #e0e0e0,
      26px 32px 0 0 #e0e0e0;
  }
  35% {
    -webkit-box-shadow: -26px 32px 0 4px #5ca0bd, 0px 32px 0 0 #e0e0e0,
      26px 32px 0 0 #e0e0e0;
    box-shadow: -26px 32px 0 4px #5ca0bd, 0px 32px 0 0 #e0e0e0,
      26px 32px 0 0 #e0e0e0;
  }
  50% {
    -webkit-box-shadow: -26px 32px 0 0 #e0e0e0, 0px 32px 0 4px #5ca0bd,
      26px 32px 0 0 #e0e0e0;
    box-shadow: -26px 32px 0 0 #e0e0e0, 0px 32px 0 4px #5ca0bd,
      26px 32px 0 0 #e0e0e0;
  }
  65% {
    -webkit-box-shadow: -26px 32px 0px 0 #e0e0e0, 0px 32px 0 0 #e0e0e0,
      26px 32px 0 4px #5ca0bd;
    box-shadow: -26px 32px 0px 0 #e0e0e0, 0px 32px 0 0 #e0e0e0,
      26px 32px 0 4px #5ca0bd;
  }
}

@keyframes dots-flow {
  0%,
  100% {
    -webkit-box-shadow: -26px 32px 0px 0 #e0e0e0, 0px 32px 0 0 #e0e0e0,
      26px 32px 0 0 #e0e0e0;
    box-shadow: -26px 32px 0px 0 #e0e0e0, 0px 32px 0 0 #e0e0e0,
      26px 32px 0 0 #e0e0e0;
  }
  35% {
    -webkit-box-shadow: -26px 32px 0 4px #5ca0bd, 0px 32px 0 0 #e0e0e0,
      26px 32px 0 0 #e0e0e0;
    box-shadow: -26px 32px 0 4px #5ca0bd, 0px 32px 0 0 #e0e0e0,
      26px 32px 0 0 #e0e0e0;
  }
  50% {
    -webkit-box-shadow: -26px 32px 0 0 #e0e0e0, 0px 32px 0 4px #5ca0bd,
      26px 32px 0 0 #e0e0e0;
    box-shadow: -26px 32px 0 0 #e0e0e0, 0px 32px 0 4px #5ca0bd,
      26px 32px 0 0 #e0e0e0;
  }
  65% {
    -webkit-box-shadow: -26px 32px 0px 0 #e0e0e0, 0px 32px 0 0 #e0e0e0,
      26px 32px 0 4px #5ca0bd;
    box-shadow: -26px 32px 0px 0 #e0e0e0, 0px 32px 0 0 #e0e0e0,
      26px 32px 0 4px #5ca0bd;
  }
}

/**
 * Extracted from: SweetAlert
 * Modified by: Istiak Tridip
 */
.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 10rem auto 0;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4caf50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: "";
      height: 100px;
      position: absolute;
      background: #ffffff;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: #4caf50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

/**************************** 로딩 페이지 ***************************/

.loading-div {
  position: absolute;
  z-index: 10000;
  background-color: #3d3d3d36;
}

/**************************** 로그인 ***************************/

.login-bg {
  position: fixed;
  top: 0;
  left: 0;
  background: url(../images/login/login_bg.jpg);
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100%;
  transform: scale(1);
  animation: visualScale 8s ease 0s forwards;
  overflow: hidden;
}

.login {
  overflow: hidden;
}

.logo-image {
  background: url(../images/login/login_img.png);
  background-position: center;
  background-size: cover;
  position: relative;
}

.c-logo-image {
  background: var(--logo-img) no-repeat;
  width: 10rem;
  height: auto;
  padding-top: var(--padding-top);
  background-size: contain;

  &.c-sidebar-logo {
    height: 36px;
    background-position: center;
  }
}

.login-title {
  color: #3d3d3d;
  background-color: #fff;
  font-weight: 900;
  height: 50px;
}

/**************************** 사이드바 ***************************/
.interface-item {
  span {
    font-weight: normal;
  }
}

#accordionSidebar {
  .interface-item span {
    margin-left: 15px;
  }

  &.toggled .interface-item span {
    margin-left: 0px !important;
  }

  .menu-accordion {
    display: block;
  }

  &.toggled .menu-accordion {
    display: none;
  }
}

ul#accordionSidebar.mobile {
  .interface-item span {
    margin-left: 0px !important;
  }

  .menu-accordion {
    display: none;
  }
}
/**************************** footer ***************************/
footer {
  width: 100%;
  margin-top: 1rem;
  background-color: #eee;

  .footer-info {
    padding: 3rem;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .footer-group {
    display: grid;
    font-size: 13px;

    @media (max-width: 1000px) {
      margin-bottom: 2rem;
    }

    &.com-info {
      margin: 0;
      @media (max-width: 1000px) {
        margin-bottom: 2rem;
      }
    }

    &.customer-group {
      span,
      div {
        margin-bottom: 1rem;
      }
    }

    .footer-sub-group {
      span,
      b {
        margin-right: 0.5rem;
      }
    }
    h5 {
      margin-bottom: 1rem;
    }

    .payinfo {
      margin-top: -0.75in;

      @media (max-width: 1000px) {
        margin-top: 0rem;
      }
    }
  }
}

/**************************** 가입 정보 탭 ***************************/

.table-stats {
  padding: 0.5rem 0.1rem;

  .highlight {
    display: inline;
    font-weight: bold;
  }

  .notice-ul {
    margin-top: 1rem;
    margin-bottom: 0;
    padding: 1.5rem 0 0 1.5rem;
    line-height: 2;
    border-top: 1px solid #e3e6f0;
    word-break: keep-all;
  }

  .info-table {
    width: max-content;
    // width: 83.5vw;
    line-height: 1;
    border-color: #dee2e6;

    .payment-tr th,
    .state-tr th {
      text-align: center;
      border-right: 2px solid #000;
      width: 12rem;
      height: 45px;
    }

    .state-tr span {
      display: flex;
      align-items: center;
    }

    tr {
      td {
        padding: 0 1rem !important;

        div.group-btn {
          display: flex;
        }

        button {
          svg {
            margin-right: 0.5rem;
          }
          padding: 0.1rem 0.6rem;
          display: flex;
          align-items: center;
        }
      }
    }

    input.price {
      width: 130px;
      text-align: right;
      padding: 0.5rem;
      border: 1px solid #afafaf !important;

      &.leftover {
        color: #5a9bb5;
        font-size: 23px;
        font-weight: 900;
      }
    }

    input.price:disabled,
    [readonly] {
      // background-color: #fff !important;
      border: none !important;
      line-height: 3;
    }

    .leftover-div {
      display: flex;
      width: 50rem;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;
    }

    &.usage-table {
      th {
        border-right: 2px solid #000 !important;
        width: 12rem;
        text-align: center;
      }
    }

    span.cancel-state-info {
      color: red;
      button {
        display: none;
      }
    }
  }

  table.leftover-table {
    .leftover-th {
      text-align: center;
      width: 12rem;
      border-right: 2px solid #111;
    }

    select.form-control {
      width: 8rem;
      text-align: right;
      margin: 0.5rem 0;
    }

    span {
      display: flex;
      align-items: center;

      .price {
        margin-right: 0.2rem !important;
      }
    }

    table {
      width: 30rem;

      th,
      td {
        height: 45px;
      }

      td {
        input.form-control {
          width: 15rem;
          margin: 0 2.4rem;
        }
      }
    }
  }
}

.user-auth-wrapper {
  display: grid;
  place-items: center;
  position: absolute;
  min-height: 87dvh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.user-auth-modal {
  width: fit-content;
  height: max-content;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 5px;
}

.user-title {
  width: 100%;
  line-height: 1.8;
  padding: 3px 10px;
  margin-bottom: 1.2rem;
  border-bottom: 1px solid #e3e6f0;
  display: flex;
  align-items: center;
}

.user-form {
  padding: 0.2rem 1.5rem 0.2rem 1.5rem;

  tr {
    height: 49px;
  }

  span {
    margin-right: 1rem;
    color: darkgray;
  }

  input {
    width: 200px;

    &.auth-id-input {
      font-weight: bold;
      border: none !important;
    }
  }

  .error-notice {
    color: red;
  }
}

.user-btn {
  text-align: center;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  padding: 0.5rem 0;
  border-top: 1px solid #e3e6f0;

  button {
    margin: 0 8px;
    padding: 0.5rem 1.5rem;
  }
}

.modal-input {
  display: flex;
}

.readonly-div {
  pointer-events: none;
}

.red-color {
  color: red !important;
}

/**************************** 사용 현황 탭 ***************************/
div.leftover-div div.send-qty-info {
  width: 60%;
  p {
    color: orangered;
    margin-bottom: 10px;
    font-size: 18px;
  }

  table {
    margin-bottom: 0rem;
    thead#send-thead tr th {
      border-right: 1px solid #dee2e6 !important;
    }

    td {
      height: 45px;
      padding: 0.5rem 0.3rem !important;

      span {
        font-size: 14px;
      }

      small {
        font-size: 13px;
        color: #aaaaaa;
      }
    }
  }
}

#per-case-table,
#add-charge-table {
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
}

#regular-pay-table {
  width: 30%;
  @media (max-width: 1024px) {
    width: 50%;
  }
}

.add-table {
  margin: 0rem;

  .addPayment-tr {
    cursor: pointer;
  }

  tr th {
    border: 1px solid #dee2e6;
    border-bottom: 2px solid #212529;
    font-weight: bold;
    color: #000;
    word-break: keep-all;
    font-size: 15px;
  }

  td {
    border: 1px solid #dee2e6;
    vertical-align: middle;
    color: #3b3e40;
    height: 45px;

    label {
      width: 100%;
      height: 100%;

      input[name="add-charge"] {
        height: fit-content !important;
      }
    }
  }
}

.charge-btn {
  width: 8rem;
  height: 3rem;
  margin-top: 1rem;
  font-size: 20px;
  border-radius: 100px;
}

/**************************** 결제 내역 탭 ***************************/
div.search-div {
  color: #111 !important;
  flex-direction: column;
  margin-bottom: 1.3rem;

  button {
    margin-right: 0.5rem;

    &.period_search_css {
      &.active {
        background-color: #858796;
        color: #fff;
      }
    }
  }

  .search-btn {
    display: flex;
    align-items: center;

    @media (max-width: 800px) {
      display: block;

      div {
        margin-bottom: 0.7rem;
      }
    }
  }

  @media (max-width: 1200px) {
    div.period-search > div {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
  }

  @media (max-width: 1400px) {
    div.period-search {
      flex: 0 0 100%;
      max-width: 100%;
    }

    div.period-search > div {
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 0.7rem;
    }
  }

  @media (max-width: 580px) {
    div.period-search .col-lg-8 div:nth-child(2) {
      margin: 0.5rem 0;
    }

    div.period-search .col-lg-8 .col-lg-4 {
      max-width: 100%;
    }
  }

  .search-input {
    display: flex;
    margin-left: 3rem;
    align-items: center;

    input[type="date"] {
      width: 30rem;
    }

    button {
      margin-left: 1rem;
    }
  }

  .input-con {
    float: inline-start;
  }
}

table.history-table {
  min-height: 8rem;
}

button.printer-icon {
  color: #3b3e40 !important;
  border: none;
  background-color: #fff;
  &:hover {
    cursor: pointer;
  }
}

div.mod-modal,
div.auth-modal,
div.alert-modal,
div.receipt {
  display: flex !important;
  position: fixed;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1200px) {
  .resize-mod-modal {
    display: flex !important;
    position: fixed;
    align-items: center;
    justify-content: center;
  }
}

div.auth-modal {
  .modal-title {
    line-height: 1.2;
  }
  .modal-footer {
    justify-content: space-evenly;
  }
}

div.alert-modal {
  .modal-title {
    b {
      font-size: 20px;
    }
  }

  .modal-body {
    padding: 1.5rem;

    p {
      font-size: 16px;
    }

    h5 {
      text-align: center;
      font-weight: 900;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }

  .modal-footer {
    justify-content: center;
  }
}

div.receipt {
  .modal-header {
    justify-content: flex-end;
  }

  button {
    float: right;
  }

  .receipt-title {
    border: 1px solid #858796;
    border-bottom: none;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    text-align: center;
    padding: 1rem 0;

    b {
      color: #5ca0bd;
    }
  }

  .receipt-info {
    border: 1px solid #858796;
    border-top: 1px dotted #858796;
    border-radius: 20px;
    padding: 1rem;
    color: #858796;

    table {
      width: 100%;
      thead {
        border-bottom: 1px dotted #858796;
        th {
          color: #000;
          padding-bottom: 1rem;
        }
      }
    }

    hr {
      border: 1px solid;
    }
  }

  span {
    font-weight: bold;
    color: #000;
  }

  .right {
    text-align: right;
    width: 250px;
    color: #000 !important;
  }
}

/******************* 모바일 반응형 ******************/
@media (max-width: 550px) {
  .pp-m1 {
    padding: 13px 0;
  }
}
@media (max-width: 580px) {
  .pp-m1 {
    padding: 20px 0;
  }

  div.period-search .col-lg-8 .col-lg-4 {
    max-width: 100% !important;

    button {
      width: 100%;
    }
  }
}
@media (max-width: 768px) {
  .icon-logout {
    font-size: 0.6rem;
  }
}
@media (max-width: 992px) {
  .period-search .col-lg-8 .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;

    button {
      padding: 0.5rem 0.5rem;
    }
  }
}

.input-con {
  padding: 0;
  padding-right: 0.85rem !important;
  position: relative;
}

/**************************** 관리자 페이지 ***************************/
/**************************** 대쉬보드 ***************************/
.dashboard-table {
  table {
    width: 50rem;
    // width: 49.5%;
  }
}

.charts-div {
  margin: 3rem 0;

  .dashboard-chart {
    width: 25%;

    h5 {
      margin-bottom: 3rem;
      font-weight: 900;
    }
  }
}
/**************************** 회원관리 ***************************/
a.nav-link {
  font-weight: 900 !important;
  font-size: 18px;
}

div.user-sch-box {
  margin: 0.5rem 0 !important;

  .form-group {
    padding-left: 0.1rem;
    margin-bottom: 0;
  }
}

.user-table {
  min-height: 8rem;
}

.modify-icon:hover {
  opacity: 0.8;
  cursor: pointer;
}

.c-pagination {
  float: right;
  padding: 0.7rem 0.1rem;

  &.prevent-drag {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @media (max-width: 992px) {
    float: inherit;
  }
}

.mod-modal {
  .modal-dialog {
    width: fit-content;
    max-width: fit-content;

    .modal-header {
      align-items: center;
    }

    table.modify-table {
      width: 45rem !important;

      input[type="text"] {
        text-align: right;
      }

      th {
        text-align: center;
        padding: 0 1rem;
        border-right: 2px solid #000;
      }
      td {
        min-height: 45px;
        height: 45px;

        &.service_period {
          width: 19rem;

          div {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              font-size: 18px;
              margin: 0 0.5rem;
            }
          }
        }
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: center;
  }
}

.modify-td {
  cursor: pointer;
}
/**************************** 금액설정 ***************************/

.price-component {
  .card-body {
    padding: 1rem;
    .table-stats {
      padding: 0;
    }
  }

  .price-ul {
    span.nav-link {
      font-weight: 900;
      font-size: 18px;
    }
  }

  span.price-table-span {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 45px;

    input.form-control {
      width: 80% !important;
      text-align: right;

      &.text {
        text-align: left !important;
      }
    }
  }

  .btn-row {
    padding: 0.2rem 0.35rem;

    svg {
      font-size: 23px;
      font-weight: 900;
    }
  }
}
