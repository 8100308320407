.coupon-search-mod-table {
  width: 100%;
  tr {
    height: 4rem;
  }
}

.coupon-mod-table {
  height: 25rem;
  overflow-y: scroll;
}

.coupon-mod-modal {
  tr {
    border-bottom: 1px solid #e3e6f0;

    &:last-child {
      border-bottom: none;
    }

    td {
      height: 5rem;
    }
  }
}

.mng-coupon-table tr td {
  height: 5.55rem;
}

.coupon-disabled {
  pointer-events: none;
  cursor: default;

  svg {
    opacity: 0.5;
  }
}

.coupon-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #5ca0bd;
  //   height: 100vh;

  .coupon-body {
    background: #fff;

    p {
      font-size: 3.5vw;
    }
  }

  .show-coupon {
    background: #fff;
    // border: 1px solid gray;
    box-shadow: 2px 4px 10px 0px #80808085;
    border-radius: 20px;
    word-break: keep-all;

    h4 {
      background: #3b3e40;
      color: #fff;
      padding: 0.6rem 2rem;
      font-size: 18px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }

  .careful-notice-container {
    .careful-notice-body {
      color: #fff;
      border-top: 1px dotted #fff;
      //   border-bottom: 1px dotted #fff;
    }

    p {
      color: #fff;
    }
  }
}

.coupon-send-modal {
  tr {
    height: 2.5rem;
  }
}

.modal-open {
  div.mng-coupon-mod-modal {
    .modal-dialog {
      position: fixed;
    }
    .modal-body {
      padding: 1rem 2rem;
    }
  }
}

#contextMenu {
  display: none;
  position: absolute;
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 3px 7px 0px #84929b;
  padding: 5px 15px;
  font-weight: 900;

  a {
    color: #5ca0bd;
  }
}

.pop_container {
  width: 100%;
  position: absolute;
  bottom: 8%;
  .msg_popup {
    width: fit-content;
    background-color: #3b3e40af;
    border-radius: 10px;
    color: #fff;
    transition: 0.3s;
    padding: 5px 15px;
    margin: 0 auto;
    font-size: 13px;
  }
}

.coupon-mod-modal .modal-dialog {
  max-width: 37rem;
}
.marked-exm-atalk .modal-dialog {
  max-width: 53rem !important;
}

.coupon-inquiry-modal .modal-dialog {
  max-width: 40rem;
}

.coupon-mod-modal,
.coupon-setting-modal,
.coupon-inquiry-modal {
  .modal-footer {
    justify-content: center;
  }
}

.coupon-setting-modal {
  .modal-dialog {
    max-width: 50rem;
  }

  .modal-content {
    overflow: hidden;
  }
}
