.statistic-table table {
  width: 50.45rem;
}

div.user-mng-schbox {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem !important;

  @media (max-width: 1024px) {
    display: block;

    .float-right {
      float: left !important;
    }
  }

  button.excel-btn {
    margin-left: 1rem;
    float: right !important;

    @media (max-width: 1024px) {
      float: unset !important;
      margin-left: 0;
    }
  }

  button.usermng-ecroll-btn {
    float: right;

    @media (max-width: 1024px) {
      float: inherit;
    }
  }
}

.userinfo-table {
  tbody tr {
    background: #fff;
  }
}

.member-sort {
  position: sticky;
  left: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;

  svg {
    font-size: 20px;
    &.asc {
      transform: rotate(180deg);
    }
  }
}

.notice-icon {
  position: sticky;
  right: 0;
  top: 1.1rem;
  font-size: 18px;
  transition: 0.3s;

  &:hover {
    cursor: help;
    transform: scaleX(-1);
    transition: 0.3s;
    opacity: 0.7;
  }
}

.notice-memo {
  color: #333;
  transition: 0.3s;
  position: absolute;
  background-color: #fffadf;
  box-shadow: 3px 3px 7px 0px #c9c9c9ab;
  top: 2.4rem;
  right: 1rem;
  padding: 1rem;
  width: 20rem;
  text-align: left;
  z-index: 100;
}

.usermng-modal-body {
  padding: 1.5rem 2rem;
  .usermng-mod-table {
    border: none;

    tr {
      height: 3rem;

      label.col-lg-5 {
        padding-left: 0;
      }

      input:not([type="radio"]) {
        width: 15rem;
      }

      input[type="tel"] {
        display: flex;
        flex-direction: column;
        margin: 0.5rem 0;
      }

      input[type="radio"] {
        margin-right: 0.5rem;
      }
    }
  }
}

.usermng-table {
  width: 20rem;
  border: 1px solid #dee2e6;
  margin-top: 0.5rem;

  td {
    border: 1px solid #dee2e6;
  }

  tr th {
    border-bottom: 1px solid #dee2e6;
  }

  input[type="file"] {
    border: none !important;
  }
}

.upload-loading {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  position: absolute;
  z-index: 10000;
  background-color: rgba(239, 239, 239, 0.8705882353);
}

.chartjs-size-monitor {
  height: 25rem;
}
.chart-area {
  height: fit-content;
  min-height: 25rem;

  .pie-chart {
    //
    height: 25rem;

    canvas {
      margin: 0 auto;
      height: 25rem;
    }
  }
}

.grade-btn-group {
  width: 50rem;
  padding-right: 1rem;
  @media (max-width: 1115px) {
    width: 100%;
    padding-right: 0;
  }
}

@media (max-width: 1280px) {
  .cc-col-right {
    float: left !important;
    flex: 0 0 100%;
    max-width: 100%;

    .float-right {
      float: left !important;
    }
  }
}

@media (min-width: 1281px) and (max-width: 1358px) {
  .col-lg-3 {
    max-width: 27%;
  }
}

.member-point-modal {
  .modal-dialog {
    max-width: 40rem;
  }
}

.member-coupon-modal .modal-dialog {
  max-width: 50rem;
}

.member-excel-modal {
  .modal-dialog {
    max-width: 50rem;
  }
  .modal-footer {
    justify-content: center;
  }

  table.usermng-table tbody tr {
    th,
    td {
      padding: 0.4rem 0.7rem;
    }
  }
}

/*************** 회원가입 ***************/
.register-body {
  display: flex;
  position: absolute;
  width: 100%;
  height: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fc;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.register-form-selected {
    height: auto !important;
    padding-bottom: 1rem;
  }

  .goback_div {
    position: fixed;
    background-color: #f8f9fc;
    top: 0;
    left: 0;
    padding: 1.5rem 1rem;
    z-index: 1;
    color: #3b3e40;
    height: 30px;

    span {
      font-weight: bolder;
      font-size: 2.2vh;
    }

    .back-icon {
      font-size: 3vh;
    }
  }

  .title {
    color: #3b3e40;
  }

  .register_tag {
    width: 100%;
    background-color: #fff;
    padding: 1rem 1.2rem;
    font-size: 18px;
    border-left: 1px solid #e3e6f0;
    border-right: 1px solid #e3e6f0;

    &.justify-content-sm-between {
      justify-content: space-between !important;
    }

    .menu-icon {
      svg {
        margin-right: 1rem;
      }
    }

    svg {
      font-size: 25px;

      &.next-icon {
        color: #3b3e40 !important;
        font-size: 20px;
        font-weight: bolder;

        polygon {
          fill: #3b3e40;
        }
      }
    }

    &.register {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-top: 1px solid #e3e6f0;
      // margin-bottom: 5rem;
    }
    &:last-child {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      border-bottom: 1px solid #e3e6f0;
      // margin-bottom: 5rem;
    }
    &.point {
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }

    &:active {
      background-color: #dee2e6;
      transition: 0.3s;
    }
  }
}

.register-container {
  background-color: #fff;
  border: 1px solid #e3e6f0;
  margin: 0 auto;
  width: 93%;
  position: absolute;
  // border: 20px solid #3b3e40;
  border-radius: 10px;
  padding: 1rem;

  &.acc-point-table {
    max-height: 37rem !important;
    overflow-y: scroll !important;
  }

  .register-form-input {
    border-bottom: 1px solid #e3e6f0;
    margin-bottom: 2rem;

    input {
      border: none !important;
      padding: 0.5rem;
      width: 100%;
      color: #3b3e40;

      &:focus-visible {
        outline: none !important;
      }
    }

    .password-show-type {
      -webkit-text-security: disc;
    }

    .show-pw-label {
      padding: 0.7rem;

      svg {
        font-size: 18px;
        color: #4e73df;
      }
    }
  }

  @media (max-width: 1024px) {
    div.validate-point {
      display: flex;
      flex-wrap: wrap;
    }

    div.validate-select {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 0.75rem;
    }

    div.validate-search {
      margin-left: 0 !important;
      margin-top: 0.75rem !important;
    }
  }
}
