.resve-modal-body {
  width: 40rem;

  .send-finish {
    color: #17a673;
  }

  .resve-status-td {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.service-notice {
  border: 1px solid #3d3d3d36;
  background-color: #fffadf;
  padding: 0.5rem;

  a {
    text-decoration: underline;
  }
}

/******************* Full Calendar ********************/
.calendar-container {
  width: 80%;
  margin: 0 auto;

  @media (max-width: 992px) {
    width: 100%;
  }
}

.fc .fc-scrollgrid-liquid {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  a {
    color: #5a5c69;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 992px) {
      &[title="Show 1 more event"] {
        // font-size: 0.7rem;
      }
    }
  }
}

@media (max-width: 992px) {
  .fc .fc-toolbar {
    align-items: flex-start !important;
  }
}

.fc .fc-toolbar.fc-header-toolbar {
  &:first-child {
    display: flex;

    @media (max-width: 992px) {
      flex-direction: column;
    }

    div {
      display: flex;

      @media (max-width: 992px) {
        align-items: center;
      }

      h2 {
        margin-right: 0.8rem;
        line-height: 2;
        font-size: 20px;
        width: -webkit-fill-available;
      }
    }
  }

  .fc-prev-button,
  .fc-next-button {
    margin-right: 1px;
  }

  .fc-prev-button,
  .fc-next-button {
    background: #4e73df;
    border-color: #4e73df;

    @media (max-width: 992px) {
      width: 12%;
      padding: 5px 5px 5px 3px;
      height: 70%;
      font-size: 13px;
    }

    &:hover,
    &:active {
      background-color: #2e59d9;
      border-color: #2653d4;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
    }
  }

  .fc-today-button {
    margin-left: 0;
    margin-right: 1px;
    background-color: #717384;
    border-color: #6b6d7d;

    @media (max-width: 992px) {
      width: 25%;
      padding: 5px;
      height: 70%;
      font-size: 13px;
    }

    &:hover,
    &:active {
      background-color: #6b6d7d;
      border-color: #656776;
    }
  }

  .fc-resveEncrollButton-button {
    background: #4e73df;
    border-color: #4e73df;
    @media (max-width: 992px) {
      margin-top: 1rem;
    }

    &:hover,
    &:active {
      background-color: #2e59d9;
      border-color: #2653d4;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
    }
  }
}

.fc-day-sun a {
  color: #e74a3b !important;
}

.fc-day-sat a {
  color: #4e73df !important;
}

.fc-event-title-container,
.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  cursor: pointer;
}

.fc-h-event .fc-event-title-container {
  padding-left: 4px;
}
